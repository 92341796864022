exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-5-gruende-dr-wait-zu-nutzen-js": () => import("./../../../src/pages/5-gruende-dr-wait-zu-nutzen.js" /* webpackChunkName: "component---src-pages-5-gruende-dr-wait-zu-nutzen-js" */),
  "component---src-pages-automatisierung-js": () => import("./../../../src/pages/automatisierung.js" /* webpackChunkName: "component---src-pages-automatisierung-js" */),
  "component---src-pages-go-js": () => import("./../../../src/pages/go.js" /* webpackChunkName: "component---src-pages-go-js" */),
  "component---src-pages-haev-js": () => import("./../../../src/pages/haev.js" /* webpackChunkName: "component---src-pages-haev-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kalender-js": () => import("./../../../src/pages/kalender.js" /* webpackChunkName: "component---src-pages-kalender-js" */),
  "component---src-pages-november-2020-news-js": () => import("./../../../src/pages/november-2020-news.js" /* webpackChunkName: "component---src-pages-november-2020-news-js" */),
  "component---src-pages-praxis-app-kosten-js": () => import("./../../../src/pages/praxis-app-kosten.js" /* webpackChunkName: "component---src-pages-praxis-app-kosten-js" */),
  "component---src-pages-rezepttelefon-js": () => import("./../../../src/pages/rezepttelefon.js" /* webpackChunkName: "component---src-pages-rezepttelefon-js" */),
  "component---src-pages-telefonassistent-js": () => import("./../../../src/pages/telefonassistent.js" /* webpackChunkName: "component---src-pages-telefonassistent-js" */),
  "component---src-pages-wartezimmer-app-js": () => import("./../../../src/pages/wartezimmer-app.js" /* webpackChunkName: "component---src-pages-wartezimmer-app-js" */)
}

